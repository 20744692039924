<template>
  <div id="app"
    class="home page-template-default page page-id-13 wp-custom-logo wp-embed-responsive post-image-aligned-center sticky-menu-fade sticky-enabled both-sticky-menu no-sidebar nav-below-header separate-containers header-aligned-left dropdown-hover">
    <HeadNav selectedLanguage="pt" page="mod" />

    <div id="page" class="site grid-container container hfeed">
      <div id="content" class="site-content">
        <div id="primary" class="content-area">
          <main id="maincontent" class="site-main mod-page">
            <section id="section-1" class="section-content">
              <h1 class="section-1-blog-head">
                Últimos Apps GB MOD 2024
              </h1>
              <div class="mod-wrapper">
                <div class="mod-item">
                  <img src="@/assets/logo-gb.webp" alt="gbwhatsapp" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">GB WhatsApp</h2>
                    <a class="mod-item-btn" href="https://gbwhatapks.com/downloadpage" target="_blank">
                      Baixar
                    </a>
                  </div>
                </div>
                <div class="mod-item" v-for="(item, i) in doc.modList" :key="i">
                  <img :src="item.img" :alt="item.title" class="mod-item-img"/>
                  <div class="mod-item-content">
                    <h2 class="mod-item-title">{{ item.title }}</h2>
                    <router-link class="mod-item-btn" :to="{name:`en-${item.name}`}">
                      Baixar
                    </router-link>
                  </div>
                </div>
              </div>
              <h2>O que são os WhatsApp Mod APKs?</h2>
              <p>
              Embora o aplicativo oficial do WhatsApp evolua com atualizações, seu ritmo e recursos limitados muitas vezes não atendem às demandas dos usuários. Para preencher essa lacuna, desenvolvedores habilidosos modificaram o aplicativo original, integrando recursos empolgantes e práticos além dos oferecidos pelo WhatsApp. Como resultado, mods populares como GB WhatsApp, Fouad WhatsApp e Yo WhatsApp foram desenvolvidos.
              </p>
              <p>
              Nesta página, você encontrará informações detalhadas sobre esses WhatsApp Mod APKs. Fornecemos links de download seguros, guias passo a passo e apresentações de recursos para ajudá-lo a explorar e aproveitar esses aplicativos modificados.
              </p>
              <p>
              Sinta-se à vontade para baixar o WhatsApp Mod APK que preferir agora!
              </p>
            </section>
          </main>
        </div>
      </div>
    </div>

    <div class="site-footer grid-container footer-bar-active footer-bar-align-right">
      <footer class="site-info">
        <div class="inside-site-info grid-container">
          <div class="footer-bar">
            <a href="/privacy">Política de Privacidade</a>
          </div>
          <div class="copyright-bar">
            2025 © Todos os Direitos Reservados <strong><router-link :to="{name:`${selectedLanguage}-gb`}">GBWhatsApp</router-link></strong>
          </div>
        </div>
      </footer>
    </div>
    <div v-if="$global.isMobileDevice()" style="height: 52px" aria-hidden="true" class="wp-block-spacer" />

    <div v-if="$global.isMobileDevice()" id="fixed-bottom-btn" class="fix-bottom-btn"
      @click="gotodownload()">
      Baixar GBWhatsapp APK
    </div>
  </div>
</template>

<script>
import '@/css/base.scss';
import '@/css/index.scss';
import '@/css/blog/pc.scss';
import HeadNav from '@/components/HeadNav.vue';
import doc from '@/documents/mods.js';

export default {
  name: 'Home',
  components: {
    HeadNav,
  },
  data () {
    return {
      pageName: 'home',
      selectedLanguage: 'pt',
      doc: doc,
    };
  },
  mounted () { },
  methods: {
    gotodownload () {
      let params = window.location.search;
      window.location.href = '/pt/downloadpage' + params;
    },
    jump (url) {
      window.location.href = url;
    },
    goto(page) {
      this.$router.push({
        name: `${this.selectedLanguage}-${page}`
      });
    }
  },
};
</script>
